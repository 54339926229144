import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'semantic-ui-react';
import Layout from '../components/Layout';

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;

export default ({ data }) => {
    const { siteMetadata } = data.site;
    const siteTitle = siteMetadata.title;
    const siteDescription = siteMetadata.description;
    return (
        <Layout>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                meta={[{ name: 'description', content: siteDescription }]}
                title={siteTitle + ' | About'}
            />
            <Container text>
                <h1>About</h1>
                <p>My name is Alex and this is my blog plus other stuff.</p>
                <p>
                    I hope you enjoy what you find and I hope you find what you
                    were looking for!
                </p>
                <p>
                    The static site is generated by{' '}
                    <a target="_blank" href="https://www.gatsbyjs.com/">
                        Gastby
                    </a>
                    .
                </p>
                <p>
                    The styling is taken care of mostly by{' '}
                    <a target="_blank" href="https://react.semantic-ui.com">
                        React Semantic UI
                    </a>
                    .
                </p>
            </Container>
        </Layout>
    );
};
